import ProductProvider from "./productProvider";

const Product = class {
    constructor(object) {
        if (object === undefined) {
            this.provider = new ProductProvider();
            this.id = null;
            this.code = null;
            this.name = null;
            this.hasTax = false;
            this.discount = 0.0;
            this.hasStock = false;
            this.stock = null;
            this.selectedAmount = 1; // Used in product menu
            this.imageUrl = null;

            this.price = null;
            this.price2 = null;
            this.price3 = null;
            this.price4 = null;
            this.price5 = null;
        } else {
            this.provider = new ProductProvider(object.provider)
            this.id = object.id || null
            this.code = object.code || object.id;
            this.name = object.name || null;
            this.hasTax = object.hasTax || false;
            this.discount = object.discount || 0.0;
            this.hasStock = object.hasStock || false;
            this.stock = object.stock;
            this.selectedAmount = object.selectedAmount || 1; // Used in product menu
            this.imageUrl = object.imageUrl || null;

            this.price = object.price || null;
            this.price2 = object.price2 || null;
            this.price3 = object.price3|| null;
            this.price4 = object.price4 || null;
            this.price5 = object.price5 || null;
        }
        // For menu animation purposes
        this.productSelected = false;
    }
    get priceList() {
        var list = [this.price];
        if (this.price2 !== null && this.price2 !== undefined) { list.push(this.price2.toString()); }
        if (this.price3 !== null && this.price3 !== undefined) { list.push(this.price3.toString()); }
        if (this.price4 !== null && this.price4 !== undefined) { list.push(this.price4.toString()); }
        if (this.price5 !== null && this.price5 !== undefined) { list.push(this.price5.toString()); }
        return list;
    }

    get priceMap() {
        var list = [{label: this.price}];
        if (this.price2 !== null && this.price2 !== undefined) { list.push({label: this.price2.toString()}); }
        if (this.price3 !== null && this.price3 !== undefined) { list.push({label: this.price3.toString()}); }
        if (this.price4 !== null && this.price4 !== undefined) { list.push({label: this.price4.toString()}); }
        if (this.price5 !== null && this.price5 !== undefined) { list.push({label: this.price5.toString()}); }
        return list;
    }

    get stockBadge() {
        if (this.hasStock) {
            if (this.stock <= 0) {
                return "outofstock"
            } else if (this.stock > 0 && this.stock <= 8) {
                return "lowstock"
            } else {
                return "instock"
            }
        } else { return "" }
    }
}

export default Product