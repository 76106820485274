<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<DataTable ref="dt" :value="products" v-model:selection="selectedProducts" selectionMode="multiple" dataKey="id" :paginator="true" :rows="20" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20, 35, 50]"
							currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} producto(s)" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Administre productos</h5>
							<div class="table-header-inputs-container">
								<span id="table-search-input" class="block mt-2 md:mt-0 p-input-icon-left">
									<i class="pi pi-search" />
									<AutoComplete placeholder="Buscar por nombre..." ref="autocomplete" :dropdown="false" :multiple="false" v-model="filters['global'].value" @complete="onFilterChanged($event)"></AutoComplete>
								</span>
								<span class="table-buttons-container block mt-2 md:mt-0">
									<Button icon="pi pi-plus" v-show="this.isAuthorized" class="p-button-rounded p-button-success mr-2" @click="openNew" />
									<Button icon="pi pi-trash" v-show="this.isAuthorized" class="p-button-rounded p-button-danger mr-2" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />
									<Button icon="pi pi-download" v-show="this.isAuthorized" class="p-button-rounded p-button-help mr-2" :loading="loadingDownloadButton" @click="downloadProducts" />
								</span>
							</div>
						</div>
					</template>

                    <Column field="code" header="Código" :sortable="true" headerStyle="width:19%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Código</span>
							{{slotProps.data.code}}
						</template>
					</Column>
					<Column field="name" header="Nombre" :sortable="true" headerStyle="width:19%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Nombre</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="price" header="Precio unitario" :sortable="true" headerStyle="width:19%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Precio</span>
							{{slotProps.data.price}}
						</template>
					</Column>
					<Column field="hasTax" header="IVA" :sortable="true" headerStyle="width:19%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">IVA</span>
							{{slotProps.data.hasTax ? "SI" : "NO"}}
						</template>
					</Column>
					<Column field="stock" header="Stock" :sortable="true" headerStyle="width:19%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Stock</span>
							{{ (slotProps.data.stock !== undefined && slotProps.data.stock !== null) ? slotProps.data.stock : "-" }}
						</template>
					</Column>
                    <Column field="discount" header="Descuento" :sortable="true" headerStyle="width:19%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Descuento</span>
							{{slotProps.data.discount}}
						</template>
					</Column>
                    <Column headerStyle="min-width:10rem;" v-if="this.isAuthorized">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2" @click="confirmDeleteProduct(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="Producto" :modal="true" class="p-fluid">
                    <div class="field">
						<label for="code">Código (Opcional)</label>
                        <InputText id="code" v-model.trim="product.code" required="true" autofocus :class="{'p-invalid': submitted && !product.code}" />
                    </div>
					<div class="field">
						<label for="nombre">Nombre</label>
						<InputText id="nombre" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
						<small class="p-invalid" v-if="submitted && !product.name">El nombre es obligatorio.</small>
					</div>
                    <div class="field" style="display: flex;">
						<div>
							<label for="price">Precio 1</label>
							<InputText id="price" type="number" v-model.trim="product.price" required="true" autofocus :class="{'p-invalid': submitted && !product.price}" />
						</div>
						<div style="margin-left: 5px;">
							<label for="price2">Precio 2</label>
							<InputText id="price2" type="number" v-model="product.price2" autofocus/>
						</div>
						<div style="margin-left: 5px;">
							<label for="price3">Precio 3</label>
							<InputText id="price3" type="number" v-model.trim="product.price3" autofocus/>
						</div>
                    </div>
					<div class="field" style="display: flex;">
						<div style="margin-left: 5px; width: 33%;">
							<label for="price4">Precio 4</label>
							<InputText id="price4" type="number" v-model.trim="product.price4" autofocus/>
						</div>
						<div style="margin-left: 5px; width: 33%;">
							<label for="price5">Precio 5</label>
							<InputText id="price5" type="number" v-model.trim="product.price5" autofocus/>
						</div>
						<div style="width: 33%;"></div>
                    </div>
                    <div class="field">
						<h5 style="margin-top: 1.5rem; margin-botton: 1rem;">Lleva IVA ({{ this.$store.getters.business.taxPercentage }}%)</h5>
						<InputSwitch v-model="product.hasTax" />
                    </div>
                    <div class="field">
						<label for="discount">Descuento</label>
                        <InputText id="discount" v-model.trim="product.discount" required="false" autofocus />
					</div>
                    <div class="field">
						<div style="display: flex; height: 30px; margin-top: 20px;">
							<label for="stock" style="min-width: 120px;;">Stock</label>
							<div style="width: 100%;"></div>
							<InputSwitch v-model="product.hasStock" style="margin-left: auto; min-width: 42px;;"/>
						</div>
						
                        <InputText id="stock" v-if="product.hasStock" v-model.trim="product.stock" required="false" autofocus />
					</div>
					<div class="field">
						<label for="provider">Proveedor (Opcional)</label>
                        <Dropdown id="provider" v-model="product.provider" :options="this.providers" optionLabel="company" required="false" autofocus />
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-secondary mr-2 mb-2" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-success mr-2 mb-2" @click="saveProduct" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Está seguro de querer borrar <b>{{product.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-secondary mr-2 mb-2" @click="deleteProductDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-warning mr-2 mb-2" @click="deleteProduct" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Está seguro de querer borrar los productos seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-secondary mr-2 mb-2" @click="deleteProductsDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-warning mr-2 mb-2" @click="deleteSelectedProducts" />
					</template>
				</Dialog>
			</div>
			<div class="card">
				<DataTable ref="dt" :value="saleProducts" dataKey="id" :paginator="true" :rows="20" :filters="saleProductsFilters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20, 35, 50]"
							currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} producto(s)" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Reporte de ventas por Producto/Servicio</h5>
							<div class="table-header-inputs-container">
								<span class="block mt-4 md:mt-0 p-input-icon-left">
									<AutoComplete placeholder="Buscar" :dropdown="false" :multiple="false" v-model="saleProductsFilters['global'].value" style="margin-right: 20px;"></AutoComplete>
								</span>
								<span class="table-buttons-container block mt-4 md:mt-0">
									<Calendar :showIcon="true" v-model="startFilterDate" :showTime="true" hourFormat="24" dateFormat="dd/mm/yy" placeholder="Fecha inicial" style="width: 200px; margin-right: 20px;"></Calendar>
									<Calendar :showIcon="true" v-model="endFilterDate" :showTime="true" hourFormat="24" dateFormat="dd/mm/yy" placeholder="Fecha final" style="width: 200px; margin-right: 20px;"></Calendar>
									<Button icon="pi pi-search" class="p-button-rounded p-button-help mr-2" :loading="loadingProductSaleSearchButton" @click="getProductSalesReport" />
								</span>
							</div>
						</div>
					</template>

					<Column field="code" header="Código" :sortable="true" headerStyle="width:19%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Código</span>
							{{slotProps.data.code}}
						</template>
					</Column>
					<Column field="name" header="Nombre" :sortable="true" headerStyle="width:19%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Nombre</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="sold" header="Cant. Vendida" :sortable="true" headerStyle="width:19%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Cant. Vendida</span>
							{{slotProps.data.sold}}
						</template>
					</Column>
					<Column header="Precio Unit. Promed." :sortable="true" headerStyle="width:19%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Precio Unit. Promed.</span>
							{{(slotProps.data.total / slotProps.data.sold).toFixed(2)}}
						</template>
					</Column>
					<Column field="total" header="Total Vendido" :sortable="true" headerStyle="width:19%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Total Vendido</span>
							{{slotProps.data.total.toFixed(2)}}
						</template>
					</Column>

				</DataTable>			
			</div>
		</div>
	</div>

</template>

<script>
import download from 'downloadjs';
import {FilterMatchMode} from 'primevue/api';
import httpRequest from '../../service/httpRequest';
import Product from '../../models/product';
import ProductProvider from '../../models/productProvider';

export default {
	data() {
		return {
			providers: [],
			products: [],
			saleProducts: [],
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			filters: {},
			saleProductsFilters: {},
			submitted: false,
			loadingDownloadButton: false,
			isAuthorized: true,
			startFilterDate: null,
			endFilterDate: null,
			loadingProductSaleSearchButton: false,
		}
	},
	async created() {
        this.initFilters();
        this.getProducts();
		this.isAuthorized = await this.$store.getters.getUser.role === "Administrador";
	},
	mounted() {
    },
	methods: {
		async onFilterChanged(event) {
			let filter = event.query;
            if (filter.length > 1) {
                const result = await httpRequest.getProducts(filter);
                if (result.status === 200) {
                    this.products = result.data.map(product => new Product(product));
                } else { this.products = [] }
            }
		},
		async openNew() {
			await this.getProviders();
			this.product = new Product();
			this.submitted = false;
			this.productDialog = true;
		},
		hideDialog() {
			this.selectedProducts = null;
			this.productDialog = false;
			this.submitted = false;
		},
		async saveProduct() {
			this.selectedProducts = null;
            let response = await httpRequest.saveProduct(this.product);
            if (response.status == 200) {
                let product = new Product(response.data);
                this.addProductToList(product);
            } else { console.log("Error: ", response.status); }
            this.hideDialog();
		},
        async getProducts() {
            let response = await httpRequest.getProducts();
            if (response.status == 200) {
                let products = response.data;
                this.products = products.map(function(data) { return new Product(data)});
            } else { console.log("Error: ", response.status); }
        },
		async editProduct(product) {
			await this.getProviders();
			this.product = {...product};
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			this.selectedProducts = null;
			this.product = product;
			this.deleteProductDialog = true;
		},
		async deleteProduct() {
			this.selectedProducts = null;
            let response = await httpRequest.deleteProducts([this.product.id]);
            if (response.status) { 
                this.deleteProductFromList(this.product);
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Producto eliminado', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Hemos tenido un problema borrando el producto', life: 3000});
            }
			this.deleteProductDialog = false;
		},
		async downloadProducts() {
			this.loadingDownloadButton = true;
			const data = await httpRequest.downloadProducts();
			download(data, "productos" + ".xlsx", "application/vnd.ms-excel");
			this.loadingDownloadButton = false;
		},
		confirmDeleteSelected() {
			this.deleteProductsDialog = true;
		},
		async deleteSelectedProducts() {
            let ids = this.selectedProducts.map(product => product.id);
            let response = await httpRequest.deleteProducts(ids);
            if (response.status) {
                for (const index in this.selectedProducts) { this.deleteProductFromList(this.selectedProducts[index]) }
                this.selectedProducts = null;
                this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Producto eliminado', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Hemos tenido un problema borrando los productos seleccionados', life: 3000});
            }
			this.deleteProductsDialog = false;
        },
		async getProductSalesReport() {
			this.loadingProductSaleSearchButton = true;
			let response = await httpRequest.getProductSalesReport(this.startFilterDate, this.endFilterDate);
			this.loadingProductSaleSearchButton = false;
			if (response.status) {
				this.saleProducts = [];
				for (var key in response.data) { this.saleProducts.push(response.data[key]) }
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Hemos tenido un problema obteniendo el reporte, intente nuevamente.', life: 3000});
            }
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
			this.saleProductsFilters = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
			}
        },
        productIndex(product) {
            var index = -1;
            if (product.id === null) { index = -1; return index; }
            for (var i=0; i<this.products.length; i++) {
                if (this.products[i].id === product.id) { return i; }
            }
            return index;
        },
        addProductToList(product) {
            let index = this.productIndex(product);
            if (index > -1) { this.products[index] = product }
            else { this.products = [product, ...this.products] } 
        },
        deleteProductFromList(product) {
            let index = this.productIndex(product);
            this.products.splice(index, 1);
            this.product = null;
            if (this.products.length == 0) { this.products = [] }
        },
		async getProviders() {
            let response = await httpRequest.getProviders(true);
            if (response.status == 200) {
				this.providers = response.data.map(provider => { return new ProductProvider(provider) })
            }
        },
	}
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';

@media screen and (max-width: 769px) {
	.table-header-inputs-container {
		display: block;
	}
	#table-search-input {
		margin: 15px 0px !important;
	}
}
@media screen and (min-width: 769px) {
	.table-header-inputs-container {
		display: flex;
	}
	#table-search-input {
		margin: 0px 15px;
	}
}
</style>
