const ProductProvider = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null
            this.company = null
            this.companyIdentification = null;
            this.representative = null
            this.phoneNumber = null
            this.email = null
            this.email2 = null
            this.rentRetentionPercentage = null;
            this.ivaRetentionPercentage = null;
        } else {
            this.id = object.id || null
            this.company = object.company || null
            this.companyIdentification = object.companyIdentification || null;
            this.representative = object.representative || null
            this.phoneNumber = object.phoneNumber || null
            this.email = object.email || null
            this.email2 = object.email2 || null
            this.rentRetentionPercentage = object.rentRetentionPercentage;
            this.ivaRetentionPercentage = object.ivaRetentionPercentage;
        }
    }
}

export default ProductProvider